// Import Bootstrap
@import "bootstrap/bootstrap.scss";
//@import "bootstrap/bootstrap-grid.scss";
//@import "bootstrap/bootstrap-reboot.scss";

body {
  text-align: center;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}
p {
  margin-bottom: 8px;
}
.header {
  font-size: 60px;
  @media (max-width: 700px) {
   font-size: 40px;
  }
}
.subheader {
  font-size: 20px;
  padding-bottom: 20px;
}
.center-image {
  display: block;
  margin: auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 15px;
  @media (max-width: 700px) {
    width: 100px;
    height: 100px;
  }
}
